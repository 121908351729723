import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Table, Thead, Tbody, Tr, Th, Td, Heading } from "@chakra-ui/react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Aksjonsplan = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const tableData = data.allContentfulArticleTable?.edges
  const actionPlanItem = tableData.find(t => t.node.title === "aksjonsplan")
  const actionPlanData = actionPlanItem?.node.table.tableData
  return (
    <Layout location={location} title={siteTitle} container>
      <SEO title="Aksjonsplan" />
      <Heading marginBottom="10">Aksjonsplan</Heading>
      {actionPlanData ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              {actionPlanData[0].headerRow.map(h => (
                <Th key="h">{h}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {actionPlanData.slice(1).map(r => (
              <Tr key={r[0]}>
                {r.map(rd => (
                  <Td key={rd}>{rd}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        "Aksjonsplan er ikke tilgjengelig"
      )}
    </Layout>
  )
}

Aksjonsplan.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Aksjonsplan

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulArticleTable {
      edges {
        node {
          title
          table {
            tableData
          }
        }
      }
    }
  }
`
